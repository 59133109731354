import React from "react";
import "./HeaderStyles.css";
import { useState, useEffect } from "react";
import { useUserAuth } from "../../UserAuthContext";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";

function HeaderComponent() {
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const navbarBackground = isTopOfPage
    ? "bg-black"
    : "bg-black/25 drop-shadow rounded-lg";
  const { user } = useUserAuth();
  const { logOut } = useUserAuth();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard/");
  };
  const handleClick2 = () => {
    navigate("/submissions/");
  };
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  return (
    <header className={`${navbarBackground} sticky top-0 z-50`}>
      <div className="flex items-center justify-between w-full p-3 text-white">
        <div className="flex items-center">
          <h1 className="text-lg">Beechwood W&amp;L</h1>
          {isAboveMediumScreens ? (
            <div className="ml-4 flex items-center">
              <a
                onClick={handleClick}
                className="cursor-pointer whitespace-no-wrap mr-4"
              >
                Product Submission
              </a>
              <a
                onClick={handleClick2}
                className="cursor-pointer whitespace-no-wrap"
              >
                Past Submissions
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="text-right">
          <div className="flex gap-1 flex-col">
            <p>{user?.email}</p>
            <button
              onClick={logOut}
              className="w-full pr-2 bg-white text-black rounded-md p-1 hover:bg-black hover:text-white"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderComponent;
