import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormComponent from "./components/form/FormComponent";
import HeaderComponent from "./components/header/HeaderComponent";
import LoginComponent from "./components/login/LoginComponent";
import HomeComponent from "./components/home/HomeComponent";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "./UserAuthContext";
import ProtectedRoute from "./ProtectedRoute";
import EntriesComponent from "./components/entries/EntriesComponent";

function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <div>
          <section>
            <Routes>
              <Route path="/" element={<HomeComponent />} />
              <Route path="/login" element={<LoginComponent />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <FormComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/submissions"
                element={
                  <ProtectedRoute>
                    <EntriesComponent />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </section>
        </div>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;
