import React, { useEffect } from "react";
import { useState } from "react";
import "./EntriesStyles.css";
import HeaderComponent from "../header/HeaderComponent";
import { useUserAuth } from "../../UserAuthContext";
import axios from "axios";

function EntriesComponent() {
  const [entries, setEntries] = useState([]);
  const { user } = useUserAuth();

  const getEntries = async () => {
    try {
      const response = await axios.get(
        "https://salesrep-backend.herokuapp.com/api/entries",
        {
          params: {
            email: user?.email,
          },
        }
      );
      setEntries(response.data);
      console.log(entries);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEntries();
  }, [user]);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <HeaderComponent />
      {entries.length > 0 ? (
        <div className="container mx-auto">
          <div className="py-4">
            <h1 className="text-2xl font-bold mb-4 text-center">Entries</h1>
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-left py-3 px-4 uppercase font-bold text-xs border-b border-gray-200">
                    SKU
                  </th>
                  <th className="text-left py-3 px-4 uppercase font-bold text-xs border-b border-gray-200">
                    Product
                  </th>
                  <th className="text-left py-3 px-4 uppercase font-bold text-xs border-b border-gray-200">
                    Margin
                  </th>
                  <th className="text-left py-3 px-4 uppercase font-bold text-xs border-b border-gray-200">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry) => (
                  <tr key={entry._id}>
                    <td className="py-3 px-4 border-b border-gray-200">
                      {entry.sku}
                    </td>
                    <td className="py-3 px-4 border-b border-gray-200">
                      {entry.title}
                    </td>
                    <td className="py-3 px-4 border-b border-gray-200">
                      {entry.margin * 100}%
                    </td>
                    <td className="py-3 px-4 border-b border-gray-200">
                      ${entry.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-white">No Submissions Found</h2>
        </div>
      )}
    </div>
  );
}

export default EntriesComponent;
