import React from "react";
import "./HomeStyles.css";
import jQuery from "jquery";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
const HomeComponent = () => {
  const navigate = useNavigate();
  jQuery(document).ready(function () {
    var mouseX = 0,
      mouseY = 0;
    var xp = 0,
      yp = 0;

    $(document).mousemove(function (e) {
      mouseX = e.pageX - 30;
      mouseY = e.pageY - 30;
    });

    setInterval(function () {
      xp += (mouseX - xp) / 6;
      yp += (mouseY - yp) / 6;
      $("#circle").css({ left: xp + "px", top: yp + "px" });
    }, 20);

    $(".sign-in-link").on("click", function () {
      navigate("/login");
    });
  });

  return (
    <div class="container w-full h-full bg-black mx-auto">
      <h1 class="ggv-heading">Beechwood W&L</h1>
      <button
        id="partner"
        class="sign-in-link border-2 border-white px-3 py-1"
        onClick={navigate("/login")}
      >
        Partner Sign In
      </button>
      <span id="circle" class="circle"></span>
    </div>
  );
};

export default HomeComponent;
